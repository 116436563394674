<template>
  <b-modal
    id="rooms-edit"
    :visible="visible"
    :title="$t('rooms.edit')"
    :ok-title="$t('form.actions.save')"
    :cancel-title="$t('form-create-item.cancel')"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    @ok="handleEditItem"
    @change="$emit('change', false)"
  >
    <b-form>
      <b-form-group>
        <label for="name">{{ $t("rooms.name") }}:</label>
        <b-form-input id="name" v-model="formInput.name" type="text" />
      </b-form-group>
      <b-form-group>
        <label
          class="mt-2"
          for="description"
        >
          {{ $t("form-create-item.description") }}:
        </label>
        <quill-editor v-model="formInput.description" />
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group>
            <label for="typeEvent">{{ $t("rooms.modality") }}:</label>
            <v-select
              id="typeEvent"
              v-model="selectedOption"
              :clearable="false"
              :options="typeEventsOptions"
              @input="modality"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="type">{{ $t("people.type") }}:</label>
            <v-select
              id="type"
              v-model="selectedType"
              :clearable="false"
              :options="typeOptions"
              @input="type"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <label for="app">App:</label>
        <v-select
          id="app"
          :clearable="false"
          v-model="selectedApp"
          :options="appRoom"
          label="name"
          @input="appss"
        />
      </b-form-group>
      <b-form-group v-if="selectedOption !== $t('rooms.on-site')">
        <label for="name">{{ $t('rooms.link') }}</label>
        <b-form-input
          id="name"
          v-model="formInput.startURL"
          type="text"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
      <p class="mt-3">{{ $t("form-create-item.image") }}:</p>
      <div class="border rounded p-2">
        <p class="mb-50">
          {{ $t("form-create-item.image-select") }}
        </p>
        <b-media
          no-body
          vertical-align="center"
          class="d-flex align-items-center flex-column flex-md-row"
        >
          <b-media-aside>
            <b-img
              ref="refPreviewEl"
              :src="imageSrc"
              height="110"
              width="170"
              class="rounded mr-2 mb-1 mb-md-0"
              style="background: #c0c0c0"
            />
          </b-media-aside>
          <b-media-body>
            <div class="d-inline-block d-flex align-items-center pl-2">
              <b-form-file
                v-model="itemImage"
                class="pointer"
                :accept="FileTypes"
                :placeholder="$t('form-create-item.image-placeholder')"
                :browse-text="$t('form-create-item.image-button')"
              />
            </div>
          </b-media-body>
        </b-media>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { getImageResource } from "@/@core/utils/image-utils";
import { RoomProvider } from "@copernicsw/community-common";
import RoomPlaceholder from "@/assets/images/placeholders/light/general-placeholder.jpeg";
import Service from "@/config/service-identifiers";
import FileTypes from "@/@core/constants/FileTypes";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "RoomWelcomeMeeting",
  components: {
    BRow,
    BCol,
    quillEditor,
    vSelect,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    room: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      userCanAccess: "",
      formInput: {},
      itemImage: null,
      imageSrc: null,
      selectedOption: "",
      selectedApp: "",
      selectedType: "",
      appRoom: [
        "Bluejeans",
        "Nectios Classroom",
        "Zoom.us",
        "Jisti",
      ],
      questionsToShow: [],
      isUnshareModalVisible: false,
    };
  },
  computed: {
    typeEventsOptions() {
      return [
        this.$t("rooms.online"),
        this.$t("rooms.on-site"),
        this.$t("rooms.hybrid"),
      ];
    },
    typeOptions() {
      return ["Meeting", "Webinar"];
    },
    FileTypes() {
      return FileTypes;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    backgroundImage() {
      return getImageResource(this.room.backgroundURL);
    },
    placeholder() {
      return RoomPlaceholder;
    },
    isJitsiConferencing() {
      return this.room.app === RoomProvider.jitsi;
    },
    isBlueJeans() {
      return this.room.app === RoomProvider.bluejeans;
    },
    canAccess() {
      if (this.room.Meetings && this.room.Meetings.length > 0) {
        return this.room.Meetings.some(
          (item) => item.isAccepted || item.isSection || this.userCanAccess
        );
      }
      return this.userCanAccess;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  async created() {
    await this.getApp();
    this.formInput = { ...this.room };
    this.toModality();
    this.selectedApp = this.formInput.app;
    this.toApps();
    this.selectedType =
      this.formInput.type[0].toUpperCase() + this.formInput.type.slice(1);
  },
  methods: {
    translate(string) {
      return translateTranslationTable(this.currentLocale, string);
    },
    selectQuestions() {
      this.questionsToShow = [];
      for (let row of this.appRoom) {
        this.questionsToShow.push({
          name: this.translate(row.name),
          key: row.key,
        });
      }
    },
    async getApp() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "addons/rooms/installed",
        {
          params: { communitySlug: this.communitySlug },
        }
      );
      this.appRoom[0] = "Conferencing";
      if (response.data) {
        for (let row of response.data) {
          this.appRoom.push(row.name.en);
        }
      }
      this.selectQuestions();
    },
    appss() {
      switch (this.selectedApp) {
        case "Conferencing":
          this.formInput.app = "jitsi";
          return;
        case "Zoom.us":
          this.formInput.app = "zoom";
          return;
        case "Nectios Classroom":
          this.formInput.app = "classroom";
          return;
        case "Bluejeans":
          this.formInput.app = "bluejeans";
          return;
        case "Jisti":
          this.formInput.app = "jitsi";
          return;
      }
    },
    toApps() {
      switch (this.selectedApp) {
        case 0:
          this.selectedApp = "Zoom.us";
          return;
        case 1:
          this.selectedApp = "Conferencing";
          return;
        case 2:
          this.selectedApp = "Nectios Classroom";
          return;
        case 3:
          this.selectedApp = "Bluejeans";
          return;
      }
    },
    toModality() {
      switch (this.formInput.modality) {
        case "physical":
          this.selectedOption = this.typeEventsOptions[1];
          return;
        case "hybrid":
          this.selectedOption = this.typeEventsOptions[2];
          return;
        case "online":
          this.selectedOption = this.typeEventsOptions[0];
          return;
      }
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = "physical";
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = "hybrid";
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = "online";
          return;
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = "meeting";
          return;
        case this.typeOptions[1]:
          this.formInput.type = "webinar";
          return;
      }
    },
    async videocallUsersCanAccessNow() {
      try {
        if (this.room.isEvent) {
          return true;
        }
        // TODO: use member store
        const response = await this.$store.$service[Service.BackendClient].get(
          "/videocallUsersCanAccessNow",
          {
            params: {
              roomKey: this.room.key,
            },
          }
        );
        this.userCanAccess = response.data.responseData.ok;
      } catch (error) {
        this.userCanAccess = false;
      }
    },
    async handleEditItem() {
      this.modality();
      this.appss();
      this.type();
      try {
        await this.$store.dispatch("editItem", {
          item: {
            itemType: 'rooms',
            requestConfig: {
              roomKey: this.room.key,
              name: this.formInput.name,
              description: this.formInput.description,
              modality: this.formInput.modality,
              app: this.formInput.app,
              type: this.formInput.type,
              startURL: this.formInput.startURL,
            },
          },
          file: this.itemImage,
          forceUpdate: true,
        });
        this.loading = false;
        this.$emit('edited');
        this.$emit('change', false);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-icon {
  position: absolute;
  top: -38px;
  right: 40px;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: -38px;
  right: 10px;
  cursor: pointer;
}
</style>